import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { Toaster } from '@knockrentals/knock-react';
import Button from '../Button';
import IntegrationsAPI from '../../IntegrationsAPI';

export const RemoveIntegrationButton = (props) => {
  const { property_id, engrain_unitmap_id, reloadData, setRemoveLoading } =
    props;

  // Enum of all view states the remove button can have:
  const RemoveButtonViews = {
    DEFAULT: 'DEFAULT',
    CONFIRM: 'CONFIRM',
    REQUESTED: 'REQUESTED',
    SUCCESS: 'SUCCESS',
  };

  const [currentView, setCurrentView] = useState(RemoveButtonViews.DEFAULT);

  const onError = (toastMessage, logMessage) => {
    if (logMessage) {
      console.error(logMessage);
    }
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.error);
  };

  const onSuccess = (toastMessage, logMessage) => {
    if (logMessage) {
      console.info(logMessage);
    }
    Toaster.showToast(toastMessage, 2000, Toaster.ToastClasses.success);
  };

  const handleError = (toastMessage, logMessage) => {
    onError(toastMessage, logMessage);
    setCurrentView(RemoveButtonViews.DEFAULT);
  };

  const handleSuccess = (toastMessage, logMessage) => {
    onSuccess(toastMessage, logMessage);
    setCurrentView(RemoveButtonViews.SUCCESS);
    setTimeout(() => reloadData(), 2000);
  };

  const onRequestRemove = async (property_id, engrain_unitmap_id) => {
    try {
      await IntegrationsAPI.removeEngrainIntegration(
        property_id,
        engrain_unitmap_id
      );
      handleSuccess('Successfully removed Engrain integration!');
    } catch (error) {
      handleError('Error removing Engrain integration', error);
    }
  };

  const handleRequestRemove = async (property_id, engrain_unitmap_id) => {
    setRemoveLoading(true);
    setCurrentView(RemoveButtonViews.REQUESTED);
    await onRequestRemove(property_id, engrain_unitmap_id);
    setRemoveLoading(false);
  };

  const handleConfirmRequest = () => {
    setRemoveLoading(true);
    setCurrentView(RemoveButtonViews.CONFIRM);
  };

  const handleCancelRequest = () => {
    setRemoveLoading(false);
    setCurrentView(RemoveButtonViews.DEFAULT);
  };

  const Wrapper = ({ children }) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '10px',
      }}
    >
      {children}
    </div>
  );

  const DefaultView = (
    <Button
      className="danger remove-button"
      iconType="trash"
      onClick={handleConfirmRequest}
    >
      Remove Integration
    </Button>
  );

  const ConfirmView = (
    <div>
      <span style={{ marginLeft: '5px' }}>Are you sure?</span>
      <Button
        className="danger confirm-button"
        iconType="trash"
        onClick={() => handleRequestRemove(property_id, engrain_unitmap_id)}
        style={{
          marginRight: '-5px',
        }}
      >
        Remove
      </Button>
      <Button
        className="cancel-button"
        iconType="times"
        onClick={handleCancelRequest}
      >
        Cancel
      </Button>
    </div>
  );

  const RequestedView = (
    <Wrapper>
      <CircularProgress size={15} />
      <span style={{ marginLeft: '5px' }}>Removing</span>
    </Wrapper>
  );

  const SuccessView = (
    <Wrapper>
      <i className="fa fa-check" style={{ color: '#5cb85c' }} />
      <span style={{ marginLeft: '10px' }}>Removed</span>
    </Wrapper>
  );

  switch (currentView) {
    case RemoveButtonViews.DEFAULT:
      return DefaultView;

    case RemoveButtonViews.CONFIRM:
      return ConfirmView;

    case RemoveButtonViews.REQUESTED:
      return RequestedView;

    case RemoveButtonViews.SUCCESS:
      return SuccessView;

    default:
      return DefaultView;
  }
};

export default RemoveIntegrationButton;
