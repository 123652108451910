import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';
import { PencilIcon } from '../PencilIcon';

const useStyles = makeStyles((theme) => ({
  editIcon: {
    '&.MuiSvgIcon-root path': {
      stroke: theme.palette.primary.main,
    },
  },
}));

const EditIcon = ({ 'aria-label': ariaLabel }) => {
  const classes = useStyles();
  return (
    <SvgIcon aria-label={ariaLabel} className={classes.editIcon}>
      <PencilIcon />
    </SvgIcon>
  );
};

EditIcon.propTypes = {
  'aria-label': PropTypes.string,
};

export default EditIcon;
