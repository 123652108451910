import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
} from '@knockrentals/knock-react';

export const TRANSFER_TEAM_ERROR_MESSAGES = {
  INVALID: 'Invalid master/company to transfer to',
  SAME_MASTER_USER: 'Same master user',
  LEASING_TEAM_MEMBERS_NOT_EXCLUSIVE: 'Multi-team users cannot be transferred',
  COMPANY_MIGRATION_DIRECTION_NOT_ALLOWED:
    'Transfer cannot be completed because the leasing team includes migrated analytics or admin users. To proceed, please remove this leasing team from the users in RealPage User Management',
};

export const getTransferTeamErrorMessage = (errorMessage = '') => {
  const containsKnownError = Object.values(TRANSFER_TEAM_ERROR_MESSAGES).some(
    (transferTeamErrorMessage) =>
      transferTeamErrorMessage.toUpperCase() === errorMessage.toUpperCase()
  );

  return containsKnownError ? errorMessage : 'Error transferring team';
};

class TransferTeam extends Component {
  state = {
    transferTeamMasterUsername: '',
  };

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  setTransferTeamMasterUsername(event) {
    this.setState({ transferTeamMasterUsername: event.target.value });
  }

  submit(event) {
    event.preventDefault();
    this.props.onSubmit(this.state.transferTeamMasterUsername);
  }

  cancel(event) {
    event.preventDefault();
    this.props.onCancel();
  }

  renderTransferError() {
    return (
      <div className="transfer-error">
        <div className="title">Transfer Error:</div>
        <div className="message">{this.props.transferError}</div>
      </div>
    );
  }

  render() {
    return (
      <Modal className="user-editor-container transfer-team">
        <form onSubmit={this.submit}>
          <ModalHeader>
            <h2>Transfer {this.props.leasingTeamName}</h2>
          </ModalHeader>
          <ModalContent>
            <div className="knock-react-flex">
              <label>
                Master username at new company:
                <input
                  className="knock-react-input"
                  name="teamName"
                  onChange={this.setTransferTeamMasterUsername.bind(this)}
                  onFocus={this.props.clearError}
                  type="text"
                  value={this.state.transferTeamMasterUsername}
                />
              </label>
            </div>

            {this.props.transferError && this.renderTransferError()}
          </ModalContent>

          {this.props.transferringTeamInProgress ? (
            <ModalFooter>
              <button className="knock-react-button" disabled>
                Transferring...
              </button>
            </ModalFooter>
          ) : (
            <ModalFooter>
              <button className="knock-react-button">Transfer</button>
              <button
                className="knock-react-button"
                onClick={this.cancel.bind(this)}
              >
                Cancel
              </button>
            </ModalFooter>
          )}
        </form>
      </Modal>
    );
  }
}

TransferTeam.propTypes = {
  clearError: PropTypes.func,
  leasingTeamName: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  transferringTeamInProgress: PropTypes.bool,
  transferError: PropTypes.string,
};

export default TransferTeam;
