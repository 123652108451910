import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

const PropertyInfoFaqCheckboxInput = ({
  label,
  checked,
  onChange,
  name,
  disabled = false,
  toolTipText = '',
}) => (
  <div className="property-info-edit-custom-faq-item">
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
      name={name}
      disabled={disabled}
    />
    <label>{label}</label>
    {toolTipText && (
      <Tooltip title={toolTipText}>
        <i className="fa-regular fa-question-circle"></i>
      </Tooltip>
    )}
  </div>
);

PropertyInfoFaqCheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  toolTipText: PropTypes.string,
};

export default PropertyInfoFaqCheckboxInput;
