export const removeMappedSource = (mappedSources, sourceId) =>
  mappedSources.filter(
    (mappedSource) => sourceId !== mappedSource.knock_marketing_source_id
  );

export const removeIntegrationsMappedSource = (integrations, sourceId) =>
  integrations.map((integration) => {
    const mappedSources = integration.mapped_sources || [];
    if (mappedSources.length) {
      const updatedMappedSources = removeMappedSource(mappedSources, sourceId);
      return {
        ...integration,
        mapped_sources: updatedMappedSources,
      };
    }

    return integration;
  });
