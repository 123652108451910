import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';
import AddIcon from '../AddIcon/AddIcon';

import { makeStyles, Button } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles((theme) => ({
  addQuickReplyBtn: {
    margin: '14px 16px',
  },

  customPagination: {
    float: 'right',
    overflowX: 'auto',

    '& .MuiTablePagination-input': {
      border: 'none',
    },
    '& .MuiTablePagination-caption': {
      color: theme.palette.text.secondary,
      fontSize: '12px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
    },

    '& .MuiTablePagination-actions': {
      '& .Mui-disabled .MuiSvgIcon-root': {
        color: '#E2E3E9',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.dark,
      },
    },
  },
}));

const Pagination = ({
  count,
  handleCreateQuickReply,
  labelDisplayedRows,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  const classes = useStyles();

  return (
    <td className="quick-replies-grid-pagination">
      <Button
        className={classes.addQuickReplyBtn}
        onClick={handleCreateQuickReply}
        startIcon={<AddIcon />}
        variant="text"
      >
        Create New Quick Reply
      </Button>

      <TablePagination
        className={classes.customPagination}
        component="div"
        count={count}
        labelDisplayedRows={labelDisplayedRows}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={rowsPerPageOptions}
      />
    </td>
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  handleCreateQuickReply: PropTypes.func,
  labelDisplayedRows: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
};

export default Pagination;
