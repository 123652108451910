import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';

export const COLOR_ARIA_LABEL = 'color swatch';
export const DEFAULT_STROKE_COLOR = '#D3D3D3';
export const INDETERMINATE_ARIA_LABEL = 'indeterminate color swatch';
export const MEDIUM_GREY = '#bbbbbb';
export const NONE = 'none';
export const SLASH_COLOR = '#C20013';
export const WRAPPER_ARIA_LABEL = 'color swatch wrapper';

const useStyles = makeStyles({
  indeterminate: {
    backgroundImage: `linear-gradient(45deg, ${MEDIUM_GREY} 8.33%, #dddddd 8.33%, #dddddd 50%, ${MEDIUM_GREY} 50%, ${MEDIUM_GREY} 58.33%, #dddddd 58.33%, #dddddd 100%)`,
    backgroundSize: '8.49px 8.49px',
    borderRadius: '8px',
    boxSizing: 'border-box',
    border: `2px solid ${MEDIUM_GREY}`,
  },
});

const ColorSwatch = ({ className, color, isIndeterminate, ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      aria-label={WRAPPER_ARIA_LABEL}
      className={classnames(
        isIndeterminate && classes.indeterminate,
        className
      )}
      {...props}
    >
      <svg
        aria-label={
          isIndeterminate ? INDETERMINATE_ARIA_LABEL : COLOR_ARIA_LABEL
        }
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!color && !isIndeterminate && (
          <path
            d="M 1.5 1.5 L 22.5 22.5"
            stroke={SLASH_COLOR}
            strokeWidth="1"
          />
        )}

        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="3.5"
          fill={color || NONE}
          stroke={DEFAULT_STROKE_COLOR}
        />
      </svg>
    </SvgIcon>
  );
};

ColorSwatch.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isIndeterminate: PropTypes.bool,
};

export default ColorSwatch;
