import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel } from '@material-ui/core';
import {
  ListItem,
  ListItemText,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { PreferencesSwitch } from '../PreferencesSwitch';
import { IsModifiedCheckbox } from '../IsModifiedCheckbox';
import TourHoursDaySettings from './TourHoursDaySettings';

const useStyles = makeStyles({
  tourDay: {
    '& label': {
      textTransform: 'capitalize',
    },
  },
  hourSettings: {
    '& .hours': {
      '& .MuiInputBase-root': {
        minWidth: '110px',
      },
    },
    '& .max-visitors': {
      '& .MuiInputBase-root': {
        minWidth: '60px',
      },
    },
  },
});

export const getIsSameValue = (currentHours, initialHours) => {
  const isSameValue = Object.entries(currentHours).every(
    ([currentHoursKey, value]) => value === initialHours[currentHoursKey]
  );
  return isSameValue;
};

const TourHoursDay = ({
  day,
  isBatchEdit,
  isModified,
  setHours,
  tourHoursToday,
  updateModifiedStatus,
}) => {
  const classes = useStyles();

  const { isActive } = tourHoursToday;

  const updatePreference = (preference) => {
    setHours((prevState) => ({
      ...prevState,
      [day]: {
        ...prevState[day],
        ...preference,
      },
    }));
  };

  const resetHours = (daySettings) => {
    setHours((prevState) => ({
      ...prevState,
      ...daySettings,
    }));
  };

  const getIsSwitchIndeterminate = (value) =>
    isBatchEdit && typeof value !== 'boolean';

  return (
    <ListItem key={day}>
      <ListItemText className={classes.tourDay}>
        <IsModifiedCheckbox
          customValueComparison={getIsSameValue}
          isModified={isModified}
          updateModifiedStatus={updateModifiedStatus}
          name={day}
          value={tourHoursToday}
          resetPreference={resetHours}
        />
        <FormControlLabel
          control={
            <PreferencesSwitch
              ariaLabel={day}
              isChecked={isActive || false}
              isIndeterminate={getIsSwitchIndeterminate(isActive)}
              name="isActive"
              updatePreference={updatePreference}
            />
          }
          label={day}
        />
      </ListItemText>

      {isActive && (
        <ListItemText
          className={classes.hourSettings}
          primaryTypographyProps={{ align: 'right' }}
        >
          <TourHoursDaySettings
            tourHoursToday={tourHoursToday}
            updatePreference={updatePreference}
          />
        </ListItemText>
      )}
    </ListItem>
  );
};

TourHoursDay.propTypes = {
  day: PropTypes.string,
  isBatchEdit: PropTypes.bool,
  isModified: PropTypes.bool,
  setHours: PropTypes.func,
  tourHoursToday: PropTypes.shape({
    endTime: PropTypes.string,
    isActive: PropTypes.bool,
    maxVisitors: PropTypes.number,
    startTime: PropTypes.string,
    weekday: PropTypes.number,
  }),
  updateModifiedStatus: PropTypes.func,
};

export default TourHoursDay;
