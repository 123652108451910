import React from 'react';
import { Tooltip } from '@material-ui/core';
import './PropertyInfoEditCustom.scss';

const InputCustomText = (props) => {
  return (
    <div className="input-container">
      <div className="textarea-header-container knock-react-flex">
        <div className="input-label-text">{props.textAreaTitle}</div>
        <Tooltip title={props.toolTipText}>
          <i className="fa-regular fa-question-circle"></i>
        </Tooltip>
      </div>
      <div className="knock-react-flex">
        <textarea
          value={props.textAreaValue}
          onChange={props.inputChanged(props.textAreaValueField)}
          onBlur={props.inputChanged(props.textAreaValueField, true)}
          maxLength={327}
        />
      </div>
      <div className="flex-end">
        <div>{327 - props.textAreaValue.length} characters left</div>
      </div>
    </div>
  );
};

export default InputCustomText;
