import React, { Component, Fragment } from 'react';
import {
  PhoneRegistrationColumnCells,
  PhoneRegistrationColumnHeaders,
  PhoneRegistrationDefaultColumnCells,
  PhoneRegistrationStatusIcon,
} from './PhoneRegistration';
import PropertySourceCosts from './PropertySourceCosts';
import SourceRelayPhoneEditor from './PhoneRelayEditor/SourceRelayPhoneEditor';
import AddSourceTrackingNumbers from './PhoneRelayEditor/AddSourceTrackingNumbers';
import SourceRelayPhoneRemover from './PhoneRelayEditor/SourceRelayPhoneRemover';
import GreetingRecordingEditor from './PhoneRelayEditor/GreetingRecordingEditor';
import SearchRelayNumbers from './PhoneRelayEditor/SearchRelayNumbers';
import SourcesAPI from './SourcesAPI';
import IntegrationAPI from '../Integrations/IntegrationsAPI';
import moment from 'moment';
import { Toaster, AuthenticationService } from '@knockrentals/knock-react';
import Collapse from '../../Collapse/Collapse';
import { Tooltip } from '@knockrentals/knock-shared-web';
import PropTypes from 'prop-types';
import { FeatureFlagContext } from '../../Context/FeatureFlagContext';
import { ENVIRONMENT } from '../../config';
import './_propertySources.scss';

export const autoMapTooltipText =
  'Auto-map Knock sources to PMS sources for all other properties';

class PropertySources extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedSourceIndex: null,
      editingMappingSource: {},
      editingRelaySource: null,
      showSourceRelayPhoneRemover: false,
      isLoaded: false,
      recordingUrl: '',
      residentRecordingUrl: '',
      isAudioLoading: false,
      isResidentAudioLoading: false,
      isAudioPlaying: false,
      isResidentAudioPlaying: false,
      shouldRecordOutbound: false,
      isShouldRecordOutboundLoading: false,
      integrationsSynchronizing: {},
      sourcesToAddTracking: [],
      showSearchRelayModal: false,
      checkboxValues: {},
      relaySourcesToDelete: {},
      awaitingAPIResponse: false,
    };
    this.getAvailableTrackingNums = this.getAvailableTrackingNums.bind(this);
  }

  static propTypes = {
    property: PropTypes.object.isRequired,
    updatePropertySourceMapping: PropTypes.func.isRequired,
  };

  isInternalMode = AuthenticationService._internalMode;
  isRoleMaster = AuthenticationService.getRole() === 'master';

  isPropertyPhoneRegistrationVisible = () =>
    this.isInternalMode && this.isRoleMaster;

  getAvailableTrackingNums(availableNums, countryCode) {
    this.setState({
      availableTrackingNums: availableNums,
      countryCode: countryCode,
    });
  }

  _audioPlayer = null;
  _audioPlayerResident = null;
  _sourceIsEnabledCache = {};

  synchronizeIntegration(integration) {
    const integrationsSynchronizing = this.state.integrationsSynchronizing;

    integrationsSynchronizing[integration.vendor_property_id] = true;

    this.setState({ integrationsSynchronizing });

    return IntegrationAPI.synchronizeIntegration(
      this.props.property.id,
      integration.vendor_property_id,
      integration.vendor
    )
      .then(() => {
        window.location.reload();
      })
      .finally(() => {
        const integrationsSynchronizing = this.state.integrationsSynchronizing;

        integrationsSynchronizing[integration.vendor_property_id] = false;

        this.setState({ integrationsSynchronizing });
      });
  }

  render() {
    const propertyLocationData = this.props.property.data.location;
    const propertyName = propertyLocationData.name;
    let title = `${propertyName} - ${propertyLocationData.address.city}, ${propertyLocationData.address.state}`;

    if (this.state.isOpen && !this.state.isLoaded) {
      title = (
        <span>
          {title} <i className="fa fa-spin fa-spinner" />
        </span>
      );
    }

    const mappingHeaders = (this.props.property.integrations || []).map(
      (integration) => {
        const isSynchronizing =
          this.state.integrationsSynchronizing[integration.vendor_property_id];
        const clickableClass = isSynchronizing
          ? 'fa fa-refresh fa-fw fa-spin'
          : 'fa fa-refresh fa-fw';

        const integrationSyncClickable = (
          <a
            href="#"
            onClick={() => {
              this.synchronizeIntegration(integration);
            }}
          >
            <i className={clickableClass} />
          </a>
        );

        return (
          <th key={integration.vendor_property_id}>
            Mapping ({integration.vendor_property_id} -{' '}
            {integrationSyncClickable})
            {this.isRoleMaster && this.isAutoMappingActive() && (
              <Tooltip title={autoMapTooltipText}>
                <span
                  className="actionable-icon"
                  onClick={this.handleAutoMappingOnClick.bind(this)}
                >
                  <i className="fa fa-lg fa-code-branch" />
                </span>
              </Tooltip>
            )}
          </th>
        );
      }
    );

    const greetingRepeatOptions = [
      <option key={1} value={0}>
        Play 1 time
      </option>,
      <option key={2} value={1}>
        Play 2 times
      </option>,
      <option key={3} value={2}>
        Play 3 times
      </option>,
      <option key={4} value={3}>
        Play 4 times
      </option>,
      <option key={5} value={4}>
        Play 5 times
      </option>,
    ];

    const greetingDelayOptions = [
      <option key={0} value={0}>
        None
      </option>,
      <option key={1} value={1}>
        1 second
      </option>,
      <option key={2} value={2}>
        2 seconds
      </option>,
      <option key={3} value={3}>
        3 seconds
      </option>,
      <option key={4} value={4}>
        4 seconds
      </option>,
      <option key={5} value={5}>
        5 seconds
      </option>,
    ];

    const PropertyPhoneRegistration = this.props.PropertyPhoneRegistration;

    return (
      <Collapse title={title} onToggled={this.onToggled.bind(this)}>
        {this.isPropertyPhoneRegistrationVisible() && (
          <PropertyPhoneRegistration />
        )}

        <div className="hidden-elements">
          <audio
            ref={(player) => {
              this._audioPlayer = player;
            }}
            onEnded={this.toggleAudioPlayingState.bind(this, false)}
            controls
          >
            <source src={this.state.recordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
          <audio
            ref={(player) => {
              this._audioPlayerResident = player;
            }}
            onEnded={this.toggleResidentAudioPlayingState.bind(this, false)}
            controls
          >
            <source src={this.state.residentRecordingUrl} type="audio/mpeg" />
            Your browser does not support the audio element.
          </audio>
        </div>

        <div id="SourceRelayPhoneEditor" className="property-sources">
          {this.state.editingRelaySource && (
            <SourceRelayPhoneEditor
              greetingDelayOptions={greetingDelayOptions}
              greetingRepeatOptions={greetingRepeatOptions}
              onCancel={this.cancelPhoneRelayEdit.bind(this)}
              onSubmit={this.onRelayUpdated.bind(this)}
              propertyId={this.props.property.id}
              relayPhone={this.state.editingRelaySource.relayPhone}
              source={this.state.editingRelaySource.source}
              sources={this.props.property.sources}
            />
          )}

          {this.state.showSearchRelayModal &&
          !this.state.availableTrackingNums ? (
            <SearchRelayNumbers
              internal={AuthenticationService._internalMode}
              onCancel={this.onCancelAddSourceTracking.bind(this)}
              callbackFromParent={this.getAvailableTrackingNums}
              sourcesToAddTracking={this.state.sourcesToAddTracking}
            />
          ) : null}

          {this.state.showSearchRelayModal &&
            this.state.availableTrackingNums && (
              <AddSourceTrackingNumbers
                availableTrackingNums={this.state.availableTrackingNums}
                countryCode={this.state.countryCode}
                greetingDelayOptions={greetingDelayOptions}
                greetingRepeatOptions={greetingRepeatOptions}
                internalMode={AuthenticationService._internalMode}
                onCancel={this.onCancelAddSourceTracking.bind(this)}
                onSubmit={this.onAddNewSourceTracking.bind(this)}
                sourcesToAddTracking={this.state.sourcesToAddTracking}
              />
            )}

          {this.state.showSourceRelayPhoneRemover ? (
            <SourceRelayPhoneRemover
              relaySourcesToDelete={this.state.relaySourcesToDelete}
              property={this.props.property}
              onSubmit={this.onDeleteSourceRelayNumbers}
              onCancel={this.cancelPhoneRelayRemove.bind(this)}
            />
          ) : null}

          {this.state.isShowRecordingEditModal ? (
            <GreetingRecordingEditor
              propertyId={this.props.property.id}
              recordingType={this.state.isShowRecordingEditModal}
              onCancel={this.toggleRecordingEditModal.bind(this, false)}
              onSubmit={false}
            />
          ) : null}

          <table className="wire-frame-table">
            <thead>
              <tr>
                <th>Source</th>
                <th>Email</th>
                <th>Tracking email</th>
                <th>Tracking number</th>

                {this.isPropertyPhoneRegistrationVisible() && (
                  <PhoneRegistrationColumnHeaders />
                )}

                <th>Prospect destination</th>
                <th>Resident destination</th>
                <th>Fee type</th>
                <th>Fee amount</th>
                {mappingHeaders}
                {AuthenticationService._internalMode && <th>Enabled</th>}
              </tr>
            </thead>
            <tbody>
              {this.renderDefaultSources()}
              {this.renderPropertySources()}
            </tbody>
          </table>

          {this.state.selectedSourceIndex !== null ? (
            <PropertySourceCosts
              feeTypes={this.props.feeTypes}
              property={this.props.property}
              source={
                this.props.property.sources[this.state.selectedSourceIndex]
              }
              onCancel={this.onCancelEditCosts.bind(this)}
              onCostsChanged={this.onCostsChanged.bind(this)}
            />
          ) : null}
        </div>
      </Collapse>
    );
  }

  onToggled(isOpen) {
    this.setState({ isOpen: isOpen });
    if (isOpen && !this.state.isLoaded) {
      this.getData().then(() => {
        this.setState({ isLoaded: true });
      });
    }
  }

  getData() {
    const initPromises = [
      this.retrieveRecordingUrl(),
      this.getPropertySources(),
    ];

    return Promise.all(initPromises);
  }

  getPropertySources() {
    this.setState({ isLoaded: false });
    const includeDisabled = AuthenticationService._internalMode;

    return SourcesAPI.getPropertiesSources(
      [this.props.property.id],
      includeDisabled
    )
      .then(({ property_sources_data }) => {
        const propertyData = property_sources_data.find(
          (property) => property.id === this.props.property.id
        );

        this.props.onDataRetrieved(this.props.property.id, propertyData);
      })
      .finally(() => {
        this.setState({ isLoaded: true });
      });
  }

  setDeleteCheckboxValues = (event) => {
    const checked = event.target.checked;
    let checkboxValues = {};

    this.props.property.sources.forEach((source) => {
      if (source.relayPhones) {
        checkboxValues[source.id] = checkboxValues[source.id] || {};

        source.relayPhones.forEach((relayPhone) => {
          checkboxValues[source.id][relayPhone.relayPhoneId] = {
            delete: checked,
            details: {
              source: source,
              relayPhone: relayPhone,
            },
          };
        });
      }
    });

    return this.setState({ relaySourcesToDelete: checkboxValues });
  };

  openSourceRelayPhoneRemover = () => {
    let relaySourcesToDelete = this.state.relaySourcesToDelete;
    if (relaySourcesToDelete) {
      for (const relaySourceId in relaySourcesToDelete) {
        for (const relayPhoneId in relaySourcesToDelete[relaySourceId]) {
          const sourceRelayPhonePayload =
            relaySourcesToDelete[relaySourceId][relayPhoneId];
          //only open sourceRelayPhoneRemover if at least one source marked for deletion
          if (sourceRelayPhonePayload && sourceRelayPhonePayload.delete) {
            return this.setState({ showSourceRelayPhoneRemover: true });
          }
        }
      }
    }
  };

  onChangeDeleteCheckbox = (event) => {
    let currentCheckboxValues = this.state.relaySourcesToDelete;
    const sourceId = event.target.value.split(':')[0];
    const relayPhoneId = event.target.value.split(':')[1];
    const checked = event.target.checked;
    const sources = this.props.property.sources;

    const source = sources.find((s) => s.id === parseInt(sourceId, 10)) || {};

    const relayPhone = source.relayPhones
      ? source.relayPhones.find(
          (rp) => rp.relayPhoneId === parseInt(relayPhoneId, 10)
        ) || {}
      : {};

    currentCheckboxValues[sourceId] = currentCheckboxValues[sourceId] || {};
    currentCheckboxValues[sourceId][relayPhoneId] = {
      delete: checked,
      details: {
        source: source,
        relayPhone: relayPhone,
      },
    };

    // Uncheck select all if removing value so we can re-check the later
    const selectAllChecked = this.refs.selectAllDelete.checked;
    if (selectAllChecked && !checked) {
      this.refs.selectAllDelete.checked = false;
    }

    return this.setState({ relaySourcesToDelete: currentCheckboxValues });
  };

  renderDefaultSources() {
    const defaultSources = (this.props.property.integrations || []).map(
      (integration) => {
        const defaultMappedId = integration.default_integration_source
          ? integration.default_integration_source.mapped_id
          : '';

        return (
          <td key={integration.vendor_property_id}>
            <select
              value={defaultMappedId}
              onChange={(event) =>
                this.props.defaultSourceChanged(
                  this.props.property.id,
                  integration.id,
                  parseInt(event.target.value, 10)
                )
              }
            >
              <option key={null}>--unmapped--</option>
              {this.renderValidSources(integration)}
            </select>
          </td>
        );
      }
    );

    return (
      <tr>
        <td>Default</td>
        <td />
        <td />
        {AuthenticationService._internalMode ? (
          <td>
            <div name="Record Outbound">
              Record outbound
              {!this.state.isShouldRecordOutboundLoading ? (
                <input
                  name="shouldRecordOutbound"
                  type="checkbox"
                  style={{ display: 'inline-block', marginRight: '0.5rem' }}
                  checked={!!this.state.shouldRecordOutbound}
                  onChange={this.onChangeShouldRecordOutbound.bind(this)}
                />
              ) : (
                <span>
                  <i className="fa fa-lg fa-spin fa-spinner" />
                </span>
              )}
            </div>
            <div name="Delete All">
              Delete All
              <Fragment>
                <input
                  name="selectAllDelete"
                  type="checkbox"
                  style={{ display: 'inline-block', marginRight: '0.5rem' }}
                  onClick={this.setDeleteCheckboxValues}
                  ref="selectAllDelete"
                />
                <span
                  className="actionable-icon mLeft5"
                  onClick={this.openSourceRelayPhoneRemover}
                >
                  <i className="fa fa-lg fa-trash" />
                </span>
              </Fragment>
            </div>
          </td>
        ) : (
          <td />
        )}

        {this.isPropertyPhoneRegistrationVisible() && (
          <PhoneRegistrationDefaultColumnCells />
        )}

        <td>
          {this.renderPlayerIcons()}
          <span
            className="actionable-icon"
            onClick={this.toggleRecordingEditModal.bind(this, 'prospect')}
          >
            <i className="fa fa-lg fa-pencil-square" />
          </span>
        </td>
        <td>
          {this.renderResidentPlayerIcons()}
          <span
            className="actionable-icon"
            onClick={this.toggleRecordingEditModal.bind(this, 'resident')}
          >
            <i className="fa fa-lg fa-pencil-square" />
          </span>
        </td>
        <td />
        <td />
        {defaultSources}
        {AuthenticationService._internalMode && (
          <td>
            <input type="checkbox" disabled checked readOnly />
          </td>
        )}
      </tr>
    );
  }

  renderPlayerIcons() {
    if (this.state.isAudioLoading) {
      return (
        <span>
          <i className="fa fa-lg fa-spin fa-spinner" />
        </span>
      );
    } else {
      if (this.state.isAudioPlaying) {
        return (
          <span className="actionable-icon" onClick={this.stopAudio.bind(this)}>
            <i className="fa fa-lg fa-stop-circle" />
          </span>
        );
      } else {
        return (
          <span className="actionable-icon" onClick={this.playAudio.bind(this)}>
            <i className="fa fa-lg fa-play-circle" />
          </span>
        );
      }
    }
  }

  renderResidentPlayerIcons() {
    if (this.state.isResidentAudioLoading) {
      return (
        <span>
          <i className="fa fa-lg fa-spin fa-spinner" />
        </span>
      );
    } else {
      if (this.state.isResidentAudioPlaying) {
        return (
          <span
            className="actionable-icon"
            onClick={this.stopResidentAudio.bind(this)}
          >
            <i className="fa fa-lg fa-stop-circle" />
          </span>
        );
      } else {
        return (
          <span
            className="actionable-icon"
            onClick={this.playResidentAudio.bind(this)}
          >
            <i className="fa fa-lg fa-play-circle" />
          </span>
        );
      }
    }
  }

  renderPropertySources() {
    if (
      !this.props.property.sources ||
      this.props.property.sources.length === 0
    ) {
      return null;
    }

    const getEmailEnabledChangedHandler = (source, index) => {
      return (event) => {
        source.displayEmail = event.target.checked;
        this.props.onSourcesChanged(this.props.property.id, index, source);
      };
    };

    const getEditSourceCostsHandler = (sourceIndex) => {
      return (event) => {
        event.preventDefault();
        this.editSourceCosts(sourceIndex);
      };
    };

    this._sourceIsEnabledCache = {};

    const { propertyPhoneRegistrationRelayMap } = this.props;

    const pricingModels = SourcesAPI.FEE_TYPES.reduce(function (
      accumulator,
      currentPair
    ) {
      accumulator[currentPair.value] = currentPair.label;
      return accumulator;
    },
    {});
    return this.props.property.sources.map((source, index) => {
      this._sourceIsEnabledCache[source.id] = true;

      const hasRelay = source.displayEmail || source.voiceNumber;
      const className = hasRelay
        ? 'knock-react-table-row-highlight'
        : !source.isEnabled
        ? 'knock-react-table-row-dim'
        : '';

      const currentPricingModel =
        source.currentFee === null
          ? 'None'
          : pricingModels[source.currentPricingModel];

      const mappingSelects = (this.props.property.integrations || []).map(
        (integration, integrationIndex) => {
          return (
            <td key={integration.vendor_property_id}>
              {this.renderMappingSelect(
                integration,
                source,
                index,
                integrationIndex
              )}
            </td>
          );
        }
      );

      const daysOfRelayInboundInactivityBeforeFlagging = 21;

      return (
        <tr key={source.id.toString() + '-' + index} className={className}>
          <td>{source.title}</td>
          <td>
            {source.disallowDisable ? null : (
              <input
                type="checkbox"
                checked={source.displayEmail}
                onChange={getEmailEnabledChangedHandler(source, index)}
              />
            )}
          </td>
          <td>
            {source.displayEmail ? (
              <a href={`mailto:${source.trackingEmail}`}>
                {source.trackingEmail}
              </a>
            ) : (
              'None'
            )}
          </td>
          <td>
            {source.relayPhones ? (
              source.relayPhones.map((relayPhone) => {
                return (
                  <span
                    className="tracking-number-cell"
                    key={`relay-phone-${relayPhone.relayPhoneId}`}
                  >
                    <a
                      href="#"
                      onClick={this.editPhoneRelay.bind(
                        this,
                        source,
                        relayPhone
                      )}
                    >
                      {relayPhone.voiceNumber}
                    </a>
                    {AuthenticationService._internalMode ? (
                      <span>
                        {relayPhone.voiceNumberLastIncomingActivityTime ? (
                          <span>
                            {moment(
                              relayPhone.voiceNumberLastIncomingActivityTime
                            ).isAfter(
                              moment().subtract(
                                daysOfRelayInboundInactivityBeforeFlagging,
                                'days'
                              )
                            ) ? null : (
                              <span
                                title={`Last incoming date was on: ${moment(
                                  relayPhone.voiceNumberLastIncomingActivityTime
                                ).format('MM-DD-YYYY')}`}
                              >
                                <i className="fa fa-exclamation-triangle" />
                              </span>
                            )}
                          </span>
                        ) : (
                          <span title="Last incoming date was on/before: 06-10-2020">
                            <i className="fa fa-exclamation-triangle" />
                          </span>
                        )}
                      </span>
                    ) : null}
                    {AuthenticationService._internalMode ? (
                      <Fragment>
                        <input
                          type="checkbox"
                          value={`${source.id}:${relayPhone.relayPhoneId}`}
                          checked={
                            this.state.relaySourcesToDelete[source.id] &&
                            this.state.relaySourcesToDelete[source.id][
                              relayPhone.relayPhoneId
                            ]
                              ? this.state.relaySourcesToDelete[source.id][
                                  relayPhone.relayPhoneId
                                ].delete
                              : false
                          }
                          onChange={this.onChangeDeleteCheckbox}
                        />
                        <span
                          className="actionable-icon mLeft5"
                          onClick={this.onClickDeletePhoneRelayIcon.bind(
                            this,
                            source,
                            relayPhone
                          )}
                        >
                          {this.state.awaitingAPIResponse &&
                          this.state.relaySourcesToDelete[source.id] &&
                          this.state.relaySourcesToDelete[source.id][
                            relayPhone.relayPhoneId
                          ] &&
                          this.state.relaySourcesToDelete[source.id][
                            relayPhone.relayPhoneId
                          ].delete ? (
                            <i className="fa fa-lg fa-spin fa-spinner" />
                          ) : (
                            <i className="fa fa-trash" />
                          )}
                        </span>
                      </Fragment>
                    ) : null}

                    {this.isPropertyPhoneRegistrationVisible() && (
                      <PhoneRegistrationStatusIcon
                        relayPhoneId={relayPhone.relayPhoneId}
                        propertyPhoneRegistrationRelayMap={
                          propertyPhoneRegistrationRelayMap
                        }
                      />
                    )}
                  </span>
                );
              })
            ) : (
              <div>
                <a href="#" onClick={this.onAddClick.bind(this, source)}>
                  {' '}
                  Add{' '}
                </a>
                <input
                  type="checkbox"
                  name={source.id}
                  checked={
                    this.state.checkboxValues[source.id]
                      ? this.state.checkboxValues[source.id].checked
                      : false
                  }
                  onChange={this.onToggleAddTrackingNumCheckbox.bind(
                    this,
                    source
                  )}
                />
              </div>
            )}
          </td>

          {this.isPropertyPhoneRegistrationVisible() && (
            <PhoneRegistrationColumnCells
              relayPhones={source.relayPhones}
              propertyPhoneRegistrationRelayMap={
                propertyPhoneRegistrationRelayMap
              }
            />
          )}

          <td>
            {source.relayPhones
              ? source.relayPhones.map((relayPhone) => {
                  return (
                    <div key={`relay-phone-${relayPhone.relayPhoneId}`}>
                      <a
                        href="#"
                        onClick={this.editPhoneRelay.bind(
                          this,
                          source,
                          relayPhone
                        )}
                      >
                        {relayPhone.voiceNumberForwardsTo || null}
                      </a>
                    </div>
                  );
                })
              : null}
          </td>
          <td>
            {source.relayPhones
              ? source.relayPhones.map((relayPhone) => {
                  return (
                    <div key={`relay-phone-${relayPhone.relayPhoneId}`}>
                      <a
                        href="#"
                        onClick={this.editPhoneRelay.bind(
                          this,
                          source,
                          relayPhone
                        )}
                      >
                        {relayPhone.voiceNumberResidentForwardsTo || null}
                      </a>
                    </div>
                  );
                })
              : null}
          </td>
          <td>
            <a href="#" onClick={getEditSourceCostsHandler(index)}>
              {currentPricingModel}
            </a>
          </td>
          <td className="money-col">
            {source.currentFee ? '$' + source.currentFee : 'Free'}
          </td>
          {mappingSelects}
          {AuthenticationService._internalMode && (
            <td>
              <input
                aria-label="enable source"
                checked={source.isEnabled}
                onChange={this.onEnabledChanged.bind(this, source)}
                type="checkbox"
              />
            </td>
          )}
        </tr>
      );
    });
  }

  renderMappingSelect(integration, source, sourceIndex, integrationIndex) {
    const defaultSource = { id: null, mapped_name: 'Default' };
    const mappedSource =
      integration.mapped_sources.find(
        (iSource) => iSource.knock_marketing_source_id === source.id
      ) || defaultSource;

    const isEditingThisSource =
      integration.id === this.state.editingMappingSource.integrationId &&
      source.id === this.state.editingMappingSource.sourceId;

    if (!isEditingThisSource) {
      return (
        <a
          aria-label="mapped source"
          href="#"
          onClick={this.editSourceMapping.bind(this, integration.id, source.id)}
        >
          {mappedSource.mapped_name}
        </a>
      );
    }

    const getMappedSourceChanged = (event) => {
      const integrationSourceId = parseInt(event.target.value, 10);
      const updatedMapping = {
        integration_id: integration.id,
        integration_source_id: isNaN(integrationSourceId)
          ? null
          : integrationSourceId,
      };

      this.props.onSourcesChanged(
        this.props.property.id,
        sourceIndex,
        source,
        updatedMapping,
        integrationIndex
      );
    };

    return (
      <select value={mappedSource.mapped_id} onChange={getMappedSourceChanged}>
        <option value={null}>Default</option>
        {this.renderValidSources(integration)}
      </select>
    );
  }

  renderValidSources(integration) {
    return integration.integration_sources
      .sort((sourceA, sourceB) => {
        if (sourceA.name > sourceB.name) {
          return 1;
        }

        return -1;
      })
      .map((validSource) => {
        return (
          <option key={validSource.id} value={validSource.id}>
            {validSource.name}
          </option>
        );
      });
  }

  onEnabledChanged(source, event) {
    const updatedSource = {
      ...source,
      isEnabled: event.target.checked,
    };

    this.props.onLeasingTeamSourceChanged(
      this.props.property.leasing_team_id,
      this.props.property.id,
      updatedSource
    );
  }

  onChangeShouldRecordOutbound(event) {
    event.preventDefault();

    const newShouldRecordOutbound = !this.state.shouldRecordOutbound;
    this.setState({ isShouldRecordOutboundLoading: true });

    SourcesAPI.putPhoneRecordOutboundConfig(
      this.props.property.id,
      newShouldRecordOutbound
    ).then(() => {
      this.setState({
        isShouldRecordOutboundLoading: false,
        shouldRecordOutbound: newShouldRecordOutbound,
      });
    });
  }

  editSourceMapping(integrationId, sourceId, event) {
    event.preventDefault();
    this.setState({ editingMappingSource: { integrationId, sourceId } });
  }

  editSourceCosts(selectedSourceIndex) {
    this.setState({ selectedSourceIndex });
  }

  onCancelEditCosts() {
    this.setState({ selectedSourceIndex: null });
  }

  batchUpdateCosts = (sourceId, updatedFees) => {
    return [
      SourcesAPI.batchUpdatePropertySourceCosts(
        this.props.property.id,
        sourceId,
        updatedFees
      ),
    ];
  };

  onCostsChanged(updatedFees, callback) {
    const now = moment();
    const month = now.month() + 1;
    const year = now.year();

    let editedSource =
      this.props.property.sources.slice()[this.state.selectedSourceIndex];

    const currentMonthIfEdited = updatedFees.find((fee) => {
      return fee.month === month && fee.year === year;
    });

    if (currentMonthIfEdited) {
      editedSource.currentFee =
        currentMonthIfEdited.type === 'None' ? 0 : currentMonthIfEdited.cost;
      editedSource.currentPricingModel = currentMonthIfEdited.type;
    }

    const updatePromises = this.context.isBatchSourceCostEnabled
      ? this.batchUpdateCosts(editedSource.id, updatedFees)
      : updatedFees.map((fee) => {
          const costMonthMoment = moment()
            .month(fee.month - 1)
            .year(fee.year);

          return SourcesAPI.updatePropertySourceCosts(
            this.props.property.id,
            editedSource.id,
            costMonthMoment.format('YYYY-MM'),
            {
              cost: fee.type === 'None' ? 0 : fee.cost,
              pricingModel: fee.type === 'None' ? null : fee.type,
            }
          );
        });

    Promise.all(updatePromises)
      .then(() => {
        this.props.onSourcesChanged(
          this.props.property.id,
          this.state.selectedSourceIndex,
          editedSource
        );
      })
      .catch((e) => {
        Toaster.showToast(
          'Error updating costs',
          2000,
          Toaster.ToastClasses.error
        );
        console.error(e);
      })
      .finally(() => callback());
  }

  onToggleAddTrackingNumCheckbox = (source) => {
    const sourcesToAddTracking = this.state.sourcesToAddTracking;
    let checkboxValues = this.state.checkboxValues;

    if (!(source.id in checkboxValues)) {
      checkboxValues[source.id] = { checked: true };
    } else {
      checkboxValues[source.id] = {
        checked: !checkboxValues[source.id].checked,
      };
    }

    if (sourcesToAddTracking.includes(source)) {
      const index = sourcesToAddTracking.indexOf(source);

      if (index !== -1) {
        sourcesToAddTracking.splice(index, 1);
      }
    } else {
      sourcesToAddTracking.push(source);
    }

    this.setState({
      sourcesToAddTracking: sourcesToAddTracking,
      checkboxValues,
    });
  };

  onAddNewSourceTracking(relay) {
    const addingTrackingOnSources = {};

    this.setState({ isLoaded: false });

    this.state.sourcesToAddTracking.forEach((newSource, i) => {
      const newSourceId = parseInt(newSource.id, 10);
      addingTrackingOnSources[newSourceId] = true;

      const newSourceIndex = this.props.property.sources.findIndex(
        (source) => newSourceId === source.id
      );
      const newTrackingSourceTitle =
        this.props.property.sources[newSourceIndex].title;

      SourcesAPI.createPropertySourceRelay(this.props.property.id, {
        voiceNumberRaw: this.state.availableTrackingNums[i],
        voiceNumberForwardsToRaw: relay.singleForwardingNumber
          ? `+1${relay.singleForwardingNumber}`
          : `+1${relay.forwardsTo}`,
        voiceNumberResidentForwardsToRaw: relay.singleForwardingNumber
          ? `+1${relay.singleForwardingNumber}`
          : `+1${relay.residentForwardsTo}`,
        shouldRecord: relay.recordCalls,
        greetingRepeatCount: relay.greetingRepeat,
        residentGreetingRepeatCount: relay.residentGreetingRepeat,
        greetingDelaySeconds: relay.greetingDelay,
        residentGreetingDelaySeconds: relay.residentGreetingDelay,
        countryCode: this.state.countryCode,
        sourceId: newSourceId,
      })
        .then(() => {
          Toaster.showToast(
            `Saved new number for ${newTrackingSourceTitle}!`,
            2000,
            Toaster.ToastClasses.success
          );

          delete addingTrackingOnSources[newSourceId];

          if (Object.keys(addingTrackingOnSources).length === 0) {
            this.getPropertySources();

            this.setState({
              sourcesToAddTracking: [],
              showSearchRelayModal: false,
              availableTrackingNums: null,
              countryCode: null,
            });
          }
        })
        .catch((e) => {
          Toaster.showToast(
            `Error saving new number for ${newTrackingSourceTitle}`,
            2000,
            Toaster.ToastClasses.error
          );
          this.getPropertySources();
          console.error(e);
        });
    });
  }

  // Edit single sources's tracking
  onRelayUpdated(relay) {
    if (this.state.editingRelaySource.relayPhone.relayPhoneId) {
      SourcesAPI.updatePropertySourcePhoneRelay(
        this.props.property.id,
        this.state.editingRelaySource.relayPhone.relayPhoneId,
        {
          voiceNumberForwardsToRaw: '+1' + relay.forwardsTo,
          voiceNumberResidentForwardsToRaw: '+1' + relay.residentForwardsTo,
          shouldRecord: relay.recordCalls,
          greetingRepeatCount: relay.greetingRepeat,
          residentGreetingRepeatCount: relay.residentGreetingRepeat,
          greetingDelaySeconds: relay.greetingDelay,
          residentGreetingDelaySeconds: relay.residentGreetingDelay,
          isSpamDetectionEnabled: relay.isSpamDetectionEnabled,
          sourceId: parseInt(relay.sourceId, 10),
        }
      )
        .then(() => {
          Toaster.showToast(
            `Saved ${relay.relayPhoneNumber}!`,
            2000,
            Toaster.ToastClasses.success
          );
          this.getPropertySources();
          this.setState({ editingRelaySource: null });
        })
        .catch((e) => {
          Toaster.showToast(
            `Error saving ${relay.relayPhoneNumber}`,
            2000,
            Toaster.ToastClasses.error
          );
          console.error(e);
        });
    }
  }

  inputChanged(key, target, event) {
    this.setState({
      [key]: event.target.value,
    });
  }

  checkboxChanged(key, event) {
    this.setState({
      [key]: event.target.checked,
    });
  }

  onRadioIntChanged(field, event) {
    this.setState({
      [field]: parseInt(event.target.value, 10),
    });
  }

  onDeleteSourceRelayNumbers = async (isReassign) => {
    const propertyId = this.props.property.id;
    const relaySourcesToDelete = { ...this.state.relaySourcesToDelete };
    this.setState({
      awaitingAPIResponse: true,
      showSourceRelayPhoneRemover: false,
    });

    for (const sourceId in relaySourcesToDelete) {
      for (const relayPhoneId in relaySourcesToDelete[sourceId]) {
        const sourceRelayPhonePayload =
          relaySourcesToDelete[sourceId][relayPhoneId];
        if (sourceRelayPhonePayload && sourceRelayPhonePayload.delete) {
          const sourceName = sourceRelayPhonePayload.details.source.title;
          const relayNumber =
            sourceRelayPhonePayload.details.relayPhone.voiceNumberRaw;

          try {
            await SourcesAPI.removePropertySourcePhoneRelay(
              propertyId,
              relayPhoneId,
              isReassign
            );
            Toaster.showToast(
              `${sourceName} (${relayNumber}) relay phone removed!`,
              2000,
              Toaster.ToastClasses.success
            );
            delete relaySourcesToDelete[sourceId][relayPhoneId];
          } catch (e) {
            Toaster.showToast(
              `Error removing relay phone ${sourceName} (${relayNumber})`,
              2000,
              Toaster.ToastClasses.error
            );
            console.error(e);
          }
        }
      }
    }
    this.getData();
    this.setState({
      awaitingAPIResponse: false,
      relaySourcesToDelete: relaySourcesToDelete,
    });
    this.refs.selectAllDelete.checked = false;
  };

  editPhoneRelay(source, relayPhone) {
    const editingRelaySource = { source: source, relayPhone: relayPhone };
    this.setState({ editingRelaySource: editingRelaySource });
  }

  onAddClick(source) {
    const sourcesListToBulkAdd = this.state.sourcesToAddTracking;

    if (!sourcesListToBulkAdd.includes(source)) {
      sourcesListToBulkAdd.push(source);
    }

    this.setState({
      sourcesToAddTracking: sourcesListToBulkAdd,
      showSearchRelayModal: true,
    });
  }

  onClickDeletePhoneRelayIcon(source, relayPhone) {
    let currentCheckboxValues = this.state.relaySourcesToDelete;

    currentCheckboxValues[source.id] = currentCheckboxValues[source.id] || {};

    if (
      !currentCheckboxValues[source.id][relayPhone.relayPhoneId] ||
      !currentCheckboxValues[source.id][relayPhone.relayPhoneId].delete
    ) {
      currentCheckboxValues[source.id][relayPhone.relayPhoneId] = {
        delete: true,
        details: {
          source: source,
          relayPhone: relayPhone,
        },
      };
    }

    this.setState({
      relaySourcesToDelete: currentCheckboxValues,
      showSourceRelayPhoneRemover: true,
    });
  }

  cancelPhoneRelayEdit() {
    this.setState({ editingRelaySource: null });
  }

  // If user cancels clear all values for checkboxes and sources list
  onCancelAddSourceTracking() {
    const checkboxValues = this.state.checkboxValues;

    this.state.sourcesToAddTracking.map((source) => {
      return (checkboxValues[source.id] = { checked: false });
    });

    this.setState({
      checkboxValues,
      sourcesToAddTracking: [],
      showSearchRelayModal: false,
      availableTrackingNums: null,
      countryCode: null,
    });
  }

  cancelPhoneRelayRemove() {
    let setDeleteFalse = this.state.relaySourcesToDelete;
    for (const sourceId in setDeleteFalse) {
      for (const relayPhoneId in setDeleteFalse[sourceId]) {
        setDeleteFalse[sourceId][relayPhoneId].delete = false;
      }
    }

    this.refs.selectAllDelete.checked = false;

    this.setState({
      relaySourcesToDelete: setDeleteFalse,
      showSourceRelayPhoneRemover: false,
    });
  }

  toggleRecordingEditModal(recordingType) {
    this.setState({ isShowRecordingEditModal: recordingType });

    if (!recordingType) {
      this.retrieveRecordingUrl();
    }
  }

  retrieveRecordingUrl() {
    this.setState({
      isAudioLoading: true,
      isResidentAudioLoading: true,
      isShouldRecordOutboundLoading: true,
    });

    return SourcesAPI.getPhoneRecordingTemplates(this.props.property.id).then(
      ({ phone_recording_templates, should_record_outbound }) => {
        this.setState({
          isAudioLoading: false,
          recordingUrl: phone_recording_templates.greeting_url,
          isResidentAudioLoading: false,
          residentRecordingUrl: phone_recording_templates.greeting_url_resident,
          isShouldRecordOutboundLoading: false,
          shouldRecordOutbound: should_record_outbound,
        });
      }
    );
  }

  playAudio() {
    this._audioPlayer.load();
    this._audioPlayer.play();
    this.toggleAudioPlayingState(true);
  }

  stopAudio() {
    this._audioPlayer.pause();
    this.toggleAudioPlayingState(false);
  }

  toggleAudioPlayingState(isAudioPlaying) {
    this.setState({ isAudioPlaying });
  }

  playResidentAudio() {
    this._audioPlayerResident.load();
    this._audioPlayerResident.play();
    this.toggleResidentAudioPlayingState(true);
  }

  stopResidentAudio() {
    this._audioPlayerResident.pause();
    this.toggleResidentAudioPlayingState(false);
  }

  toggleResidentAudioPlayingState(isResidentAudioPlaying) {
    this.setState({ isResidentAudioPlaying });
  }

  isAutoMappingActive() {
    const tokenPayload = AuthenticationService.getTokenPayload() || {};
    const { company_id: companyId } = tokenPayload.sub || {};

    const isProduction = ENVIRONMENT === 'production';
    const isQAProductionCompany = companyId === 2007 && isProduction;

    return !isProduction || isQAProductionCompany;
  }

  handleAutoMappingOnClick() {
    const {
      property: { id: propertyId, sources, integrations },
    } = this.props;

    const integrationSourcesMap = integrations[0].integration_sources.reduce(
      (sourceMap, source) => {
        sourceMap[source.name] = source;
        return sourceMap;
      },
      {}
    );

    const defaultMappedSources = integrations[0].mapped_sources.reduce(
      (defaultMap, defaultIntegration) => {
        defaultMap[defaultIntegration.knock_marketing_source_id] =
          defaultIntegration.mapped_name;
        return defaultMap;
      },
      {}
    );

    const updatedSourceMappings = sources.reduce((updatedMappings, source) => {
      if (
        integrationSourcesMap[source.title] &&
        !defaultMappedSources[source.id] &&
        source.isEnabled
      ) {
        const sourceId = source.id;

        const updatedMapping = {
          integration_id: Number(integrations[0].id),
          integration_source_id: Number(integrationSourcesMap[source.title].id),
          knock_source_id: Number(source.id),
          knock_marketing_source_id: Number(sourceId),
          mapped_id: Number(integrationSourcesMap[source.title].id),
          mapped_name: source.title,
        };

        updatedMappings.push(updatedMapping);
      }

      return updatedMappings;
    }, []);

    this.props.updatePropertySourceMapping(propertyId, updatedSourceMappings);
  }
}

PropertySources.contextType = FeatureFlagContext;

export default PropertySources;
