import React, { useState } from 'react';
import {
  DialogContent,
  Divider,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { VerticalNavbarLink } from '@knockrentals/knock-react';
import {
  Button,
  Checkbox,
  Menu,
  MenuItem,
  ThemeProvider,
} from '@material-ui/core';
import SearchBar from '../../../../../Components/SearchBar';

const useStyles = makeStyles((theme) => ({
  filterProprty: {
    display: 'flex',
    justifyContent: 'center',
  },
  customMenuItem: {
    paddingRight: '100px !important',
  },
  filterHeader: {
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    padding: '8px 10px 8px 12px',
    textDecoration: 'none',
    cursor: 'pointer',
    border: '1px solid white',
    color: 'black',
  },
  filterHeaderClicked: {
    border: '1px solid blue',
    borderRadius: '20px',
    backgroundColor: '#B0C4DE',
  },
  arrowIconUp: {
    transform: 'rotate(180deg)',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
  },
  cancelButton: {
    color: theme.palette.primary.main,
  },
  selectButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const FilterProperty = ({ properties, onPropertySelect }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const classes = useStyles();
  const [showButtons, setShowButtons] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);

  const handleOpenDropdown = (event) => {
    setAnchorEl(event.currentTarget);
    setClicked(true);
    setShowButtons(true);
  };

  const handleCloseDropdown = () => {
    setAnchorEl(null);
    setClicked(false);
    setSearchTerm('');
    setShowButtons(false);
  };

  const handlePropertyToggle = (propertyId) => {
    setSelectedProperties((prevSelectedProperties) => {
      if (prevSelectedProperties.includes(propertyId)) {
        const updatedProperties = prevSelectedProperties.filter(
          (id) => id !== propertyId
        );
        if (updatedProperties.length === 0) {
          return [];
        } else {
          return updatedProperties;
        }
      } else {
        return [...prevSelectedProperties, propertyId];
      }
    });
  };

  const handleSelectButtonClick = () => {
    onPropertySelect(selectedProperties);
    setSelectedProperties(selectedProperties);
    handleCloseDropdown();
  };

  const handleCancelButtonClick = () => {
    onPropertySelect(properties.map(({ id }) => id));
    setSelectedProperties([]);
    handleCloseDropdown();
  };

  const getPropertiesList = () => {
    const filteredProperties = properties.filter((property) =>
      property.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return filteredProperties.length ? filteredProperties : [];
  };

  return (
    <ThemeProvider>
      <div className={classes.filterProperty}>
        <div
          className={`${classes.filterHeader} ${
            clicked ? classes.filterHeaderClicked : ''
          }`}
          onClick={handleOpenDropdown}
        >
          <VerticalNavbarLink>
            Properties{''}
            <i
              className={`${'fa fa-angle-down fa-fw support-dropdown-icon '}${
                clicked ? classes.arrowIconUp : ''
              }`}
            />
          </VerticalNavbarLink>
        </div>

        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={handleCloseDropdown}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          style={{ marginTop: '40px' }}
        >
          <DialogContent dividers={false}>
            <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            <Divider orientation="horizontal" />
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              {getPropertiesList().map((property, idx) => (
                <MenuItem
                  key={property.id}
                  className={classes.customMenuItem}
                  onClick={() => handlePropertyToggle(property.id)}
                  data-testid={`property-${idx}`}
                >
                  <Checkbox
                    color="primary"
                    checked={selectedProperties.includes(property.id)}
                    onChange={(event) => {
                      event.stopPropagation();
                      handlePropertyToggle(property.id);
                    }}
                  />
                  {property.name}
                </MenuItem>
              ))}
            </div>
            <Divider orientation="horizontal" />
            {showButtons && (
              <div className={classes.buttonContainer}>
                <Button
                  onClick={handleCancelButtonClick}
                  className={classes.cancelButton}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSelectButtonClick}
                  className={classes.selectButton}
                >
                  Select
                </Button>
              </div>
            )}
          </DialogContent>
        </Menu>
      </div>
    </ThemeProvider>
  );
};

export default FilterProperty;
