import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  archiveIcon: {
    '&.MuiSvgIcon-root path': {
      stroke: 'currentColor',
    },
  },
});

export const ArchiveSvgIcon = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon className={classnames(classes.archiveIcon, className)} {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.75 9.75V18.75C21.75 20.4069 20.4069 21.75 18.75 21.75H5.25C3.59315 21.75 2.25 20.4069 2.25 18.75V9.75M21.75 2.25H2.25C1.42157 2.25 0.75 2.92157 0.75 3.75V6.75H23.25V3.75C23.25 2.92157 22.5784 2.25 21.75 2.25ZM15 11.25C15 12.0784 14.3284 12.75 13.5 12.75H10.5C9.67157 12.75 9 12.0784 9 11.25C9 10.4216 9.67157 9.75 10.5 9.75H13.5C14.3284 9.75 15 10.4216 15 11.25Z"
          stroke="#5C5C5C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

ArchiveSvgIcon.propTypes = {
  className: PropTypes.string,
};
