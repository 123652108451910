import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import * as _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

export const PropertyTransferList = ({
  includedProperties,
  excludedProperties,
  onIncludedPropertiesChange,
  onExcludedPropertiesChange,
}) => {
  const classes = useStyles();
  const [checked, setChecked] = useState([]);

  const leftChecked = _.intersection(checked, includedProperties);
  const rightChecked = _.intersection(checked, excludedProperties);

  const handleToggle = (propertyId) => () => {
    const currentIndex = checked.indexOf(propertyId);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(propertyId);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => _.intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(_.difference(checked, items));
    } else {
      setChecked(_.union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    onExcludedPropertiesChange(excludedProperties.concat(leftChecked));
    onIncludedPropertiesChange(_.difference(includedProperties, leftChecked));
    setChecked(_.difference(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    onIncludedPropertiesChange(includedProperties.concat(rightChecked));
    onExcludedPropertiesChange(_.difference(excludedProperties, rightChecked));
    setChecked(_.difference(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ 'aria-label': `all ${title} items selected` }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((property) => {
          const labelId = `transfer-list-all-item-${property.id}-label`;

          return (
            <ListItem
              key={property.id}
              role="button"
              button
              onClick={handleToggle(property)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(property) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={property.name} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid
      container
      spacing={2}
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item data-testid="include-list">
        {customList('Include', includedProperties)}
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item data-testid="exclude-list">
        {customList('Exclude', excludedProperties)}
      </Grid>
    </Grid>
  );
};
