import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles({
  aiSparkleIcon: {
    width: '24px',
    height: '24px',
    flexShrink: 0,
  },
});

const AiSparkleSvgIcon = ({ className, ...props }) => {
  const classes = useStyles();

  return (
    <SvgIcon
      className={classnames(classes.aiSparkleIcon, className)}
      {...props}
    >
      <g clipPath="url(#clip0_10025_46719)">
        <path
          d="M21.5392 16.04C17.626 16.04 14.4427 12.8388 14.4427 8.90362C14.4427 8.75193 14.3206 8.62909 14.1697 8.62909C14.019 8.62909 13.8968 8.75193 13.8968 8.90362C13.8968 12.8388 10.7135 16.04 6.80031 16.04C6.64952 16.04 6.52734 16.1629 6.52734 16.3145C6.52734 16.4662 6.64952 16.5891 6.80031 16.5891C10.7135 16.5891 13.8968 19.7902 13.8968 23.7255C13.8968 23.8771 14.019 24 14.1697 24C14.3206 24 14.4427 23.8771 14.4427 23.7255C14.4427 19.7902 17.626 16.5891 21.5392 16.5891C21.69 16.5891 21.8122 16.4662 21.8122 16.3145C21.8122 16.1629 21.69 16.04 21.5392 16.04Z"
          fill="url(#paint0_linear_10025_46719)"
        />
        <path
          d="M14.6332 2.41264C13.3593 2.41264 12.323 1.3706 12.323 0.0894319C12.323 0.0400491 12.2832 0 12.2341 0C12.1851 0 12.1452 0.0400491 12.1452 0.0894319C12.1452 1.3706 11.109 2.41264 9.83499 2.41264C9.78591 2.41264 9.74609 2.45269 9.74609 2.50207C9.74609 2.55145 9.78591 2.59149 9.83499 2.59149C11.109 2.59149 12.1452 3.63353 12.1452 4.9147C12.1452 4.96408 12.1851 5.00413 12.2341 5.00413C12.2832 5.00413 12.323 4.96408 12.323 4.9147C12.323 3.63353 13.3593 2.59149 14.6332 2.59149C14.6824 2.59149 14.7222 2.55145 14.7222 2.50207C14.7222 2.45269 14.6824 2.41264 14.6332 2.41264Z"
          fill="url(#paint1_linear_10025_46719)"
        />
        <path
          d="M10.9276 8.45254C8.60042 8.45254 6.70741 6.54891 6.70741 4.2086C6.70741 4.11839 6.63471 4.04529 6.54502 4.04529C6.45531 4.04529 6.38263 4.11839 6.38263 4.2086C6.38263 6.54891 4.4896 8.45254 2.16239 8.45254C2.07269 8.45254 2 8.52564 2 8.61586C2 8.70607 2.07269 8.77917 2.16239 8.77917C4.4896 8.77917 6.38263 10.6829 6.38263 13.0231C6.38263 13.1134 6.45531 13.1865 6.54502 13.1865C6.63471 13.1865 6.70741 13.1134 6.70741 13.0231C6.70741 10.6829 8.60042 8.77917 10.9276 8.77917C11.0174 8.77917 11.09 8.70607 11.09 8.61586C11.09 8.52564 11.0174 8.45254 10.9276 8.45254Z"
          fill="url(#paint2_linear_10025_46719)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_10025_46719"
          x1="9.39325"
          y1="10.7426"
          x2="27.2835"
          y2="32.9323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70D8C3" />
          <stop offset="0.844127" stopColor="#062679" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_10025_46719"
          x1="10.6791"
          y1="0.688068"
          x2="16.5034"
          y2="7.91213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70D8C3" />
          <stop offset="0.844127" stopColor="#062679" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_10025_46719"
          x1="3.70437"
          y1="5.3022"
          x2="14.3438"
          y2="18.4986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#70D8C3" />
          <stop offset="0.844127" stopColor="#062679" />
        </linearGradient>
        <clipPath id="clip0_10025_46719">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

AiSparkleSvgIcon.propTypes = {
  className: PropTypes.string,
};

export default AiSparkleSvgIcon;
