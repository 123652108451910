import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemSecondaryAction } from '@material-ui/core';
import {
  Button,
  Divider,
  InputText,
  ListItem,
  ListItemText,
  makeStyles,
  RadioGroup,
} from '@knockrentals/knock-shared-web';
import { NO, YES } from '../../constants';

export const MESSAGES = {
  DUPLICATE: 'Duplicate prompt',
  REQUIRED: 'Prompt is a required field',
};

const useStyles = makeStyles({
  customDivider: {
    display: 'inline-block',
    height: '60px',
    margin: '15px 25px 0',

    '& + .MuiFormControl-root': {
      marginTop: '5px',
    },
  },
  desiredAnswerGroup: {
    marginTop: '5px',
    '& .MuiFormControlLabel-root': {
      textTransform: 'capitalize',
    },
  },
  promptField: {
    width: '180px',
    marginLeft: '20px',
  },
  customSaveButton: { marginLeft: '10px', marginRight: '20px' },
});

const desiredAnswerRadioGroupOptions = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

const AddCustomRequirement = ({
  cancel,
  customRequirements,
  saveCustomRequirement,
}) => {
  const classes = useStyles();

  const [prompt, setPrompt] = useState('');
  const [desiredAnswer, setDesiredAnswer] = useState(YES);
  const [errorMessage, setErrorMessage] = useState('');

  const isDuplicateRequirement = (prompt) => {
    const index = customRequirements.findIndex(
      (requirement) => requirement.prompt === prompt
    );
    return index !== -1;
  };

  const validatePrompt = () => {
    if (prompt.trim() === '') {
      return MESSAGES.REQUIRED;
    }
    if (isDuplicateRequirement(prompt)) {
      return MESSAGES.DUPLICATE;
    }
  };

  const handleOnClickSave = () => {
    const errorMessage = validatePrompt();
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }
    saveCustomRequirement({ prompt, desiredAnswer: desiredAnswer === YES });
  };

  const handleOnBlur = () => {
    const errorMessage = validatePrompt();
    if (errorMessage) {
      setErrorMessage(errorMessage);
    }
  };

  return (
    <ListItem divider>
      <ListItemText inset>
        <InputText
          className={classes.promptField}
          error={Boolean(errorMessage)}
          helperText={errorMessage}
          hideOptionalLabel
          label="Prompt"
          onBlur={handleOnBlur}
          onFocus={() => setErrorMessage('')}
          onChange={(e) => setPrompt(e.target.value)}
          value={prompt}
        />

        <Divider
          className={classes.customDivider}
          orientation="vertical"
          flexItem
        />

        <RadioGroup
          className={classes.desiredAnswerGroup}
          label="Desired answer"
          onChange={(e) => setDesiredAnswer(e.target.value)}
          options={desiredAnswerRadioGroupOptions}
          row
          value={desiredAnswer}
        />
      </ListItemText>

      <ListItemSecondaryAction>
        <Button color="primary" variant="outlined" onClick={cancel}>
          Cancel
        </Button>

        <Button
          className={classes.customSaveButton}
          color="primary"
          variant="contained"
          onClick={handleOnClickSave}
        >
          Save
        </Button>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

AddCustomRequirement.propTypes = {
  cancel: PropTypes.func,
  customRequirements: PropTypes.arrayOf(
    PropTypes.shape({ prompt: PropTypes.string, desiredAnswer: PropTypes.bool })
  ),
  saveCustomRequirement: PropTypes.func,
};

export default AddCustomRequirement;
