import React from 'react';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';
import { makeStyles } from '@knockrentals/knock-shared-web';

const useStyles = makeStyles((theme) => ({
  customPagination: {
    float: 'right',
    overflowX: 'auto',

    '& .MuiTablePagination-input': {
      border: 'none',
    },
    '& .MuiTablePagination-caption': {
      color: theme.palette.text.secondary,
      fontSize: '12px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.text.secondary,
    },

    '& .MuiTablePagination-actions': {
      '& .Mui-disabled .MuiSvgIcon-root': {
        color: '#E2E3E9',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.secondary.dark,
      },
    },
  },
}));

const Pagination = ({
  count,
  labelDisplayedRows,
  onChangePage,
  onChangeRowsPerPage,
  page,
  rowsPerPage,
  rowsPerPageOptions,
}) => {
  const classes = useStyles();

  return (
    <TablePagination
      className={classes.customPagination}
      colSpan={3}
      count={count}
      labelDisplayedRows={labelDisplayedRows}
      onPageChange={onChangePage}
      onRowsPerPageChange={onChangeRowsPerPage}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
};

Pagination.propTypes = {
  count: PropTypes.number,
  labelDisplayedRows: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
};

export default Pagination;
