import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { RadiusSelect } from './RadiusSelect';
import { PropertyTransferList } from './PropertyTransferList';
import { ReferralSourceSelect } from './ReferralSourceSelect';
import PropertiesAPI from '../Properties/PropertiesAPI';
import SourcesAPI from '../Sources/SourcesAPI';
import { getSafe } from './utils';
import { NearbyCommunities } from './constants';

const useStyles = makeStyles({
  root: {
    margin: 'auto',
  },
  button: {
    color: 'white',
    background: '#5cb85c',
  },
});

const PropertyInfoNearbyCommunities = ({ property, settings, onChange }) => {
  const classes = useStyles();

  /**
   * Used to access the radius state. The radius controls which sister
   * properties will be considered for referrals based on distance between the
   * selected property and the sister property.
   */
  const [radius, setRadius] = useState(NearbyCommunities.DEFAULT_RADIUS);

  /**
   * Used to access the exclusion list state. The exclusion list keeps track of
   * sister properties that have been marked as excluded from referrals.
   */
  const [exclusionList, setExclusionList] = useState([]);

  /**
   * Used to access the nearby properties state. Nearby properties are retrieved
   * from GET /property/<id>/nearby-properties.
   */
  const [nearbyProperties, setNearbyProperties] = useState([]);

  /**
   * Used to access the included properties lists state. Nearby properties are
   * divided into included/excluded "buckets".
   */
  const [includedProperties, setIncludedProperties] = useState([]);

  /**
   * Used to access the excluded properties lists state. Nearby properties are
   * divided into included/excluded "buckets".
   */
  const [excludedProperties, setExcludedProperties] = useState([]);

  /**
   * Used to access the referral source state.
   */
  const [referralSource, setReferralSource] = useState('');

  /**
   * Used to access the available referral sources state. Available referral
   * sources are retrieved from GET /admin/sources/properties.
   */
  const [availableReferralSources, setAvailableReferralSources] = useState([]);

  const fetchNearbyProperties = async (property_id) => {
    const response = await PropertiesAPI.getNearbyProperties(property_id);
    const newProperties = response.properties || [];

    const sortedNearbyProperties = [...newProperties].sort((a, b) =>
      a.name <= b.name ? -1 : 1
    );
    setNearbyProperties(sortedNearbyProperties);
  };

  const fetchPropertySources = async (propertyID) => {
    const response = await SourcesAPI.getPropertiesSources([propertyID], false);
    const propertySourcesData = response.property_sources_data || [];
    const sources = propertySourcesData.reduce((sourcesList, item) => {
      if (item.sources && item.sources.length) {
        sourcesList.push(...item.sources.filter((source) => source.is_enabled));
      }
      return sourcesList.sort((a, b) =>
        a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
      );
    }, []);

    setAvailableReferralSources(sources);
  };

  /**
   * return preference from props.property preferences JSON.
   */
  const getPropertyPreference = (preference) => {
    return getSafe(() => property.preferences.preferences[preference]);
  };

  useEffect(() => {
    fetchNearbyProperties(property.id);
    fetchPropertySources(property.id);

    const propertySearchRadius = getPropertyPreference(
      'nearby_properties_search_radius'
    );
    const preferredRadius =
      propertySearchRadius || propertySearchRadius === 0
        ? propertySearchRadius
        : NearbyCommunities.DEFAULT_RADIUS;
    const preferredExclusionList =
      getPropertyPreference('nearby_properties_excluded_properties') || [];
    const preferredReferralSource =
      getPropertyPreference('cross_sell_referral_source') || null;

    setRadius(preferredRadius);
    setExclusionList(preferredExclusionList);
    setReferralSource(preferredReferralSource);
  }, [property.preferences]);

  useEffect(() => {
    const initialProperties = { excluded: [], included: [] };

    const { excluded, included } = nearbyProperties.reduce(
      (properties, property) => {
        if (
          (property.distance <= radius ||
            radius === NearbyCommunities.MAX_RADIUS) &&
          radius !== NearbyCommunities.MIN_RADIUS
        ) {
          exclusionList.includes(property.id)
            ? properties.excluded.push(property)
            : properties.included.push(property);
        }
        return properties;
      },
      initialProperties
    );

    setIncludedProperties(included);
    setExcludedProperties(excluded);
  }, [nearbyProperties, radius]);

  const submit = (preferenceKey, preferenceValue) => {
    const existingPreferences = getSafe(() => property.preferences, {});
    const existingJsonPrefs = getSafe(
      () => property.preferences.preferences,
      {}
    );

    const updatedProperty = {
      ...property,
      preferences: {
        ...existingPreferences,
        preferences: {
          ...existingJsonPrefs,
          [preferenceKey]: preferenceValue,
        },
      },
    };

    onChange(updatedProperty);
  };

  const onRadiusChanged = (newRadius) => {
    if (radius !== newRadius) {
      setRadius(newRadius);
      submit('nearby_properties_search_radius', newRadius);
    }
  };

  const handleRadiusReset = () => {
    onRadiusChanged(NearbyCommunities.DEFAULT_RADIUS);
  };

  const handleIncludedPropertiesChange = (inclProperties) => {
    setIncludedProperties(inclProperties);
  };

  const handleExcludedPropertiesChange = (exclProperties) => {
    setExcludedProperties(exclProperties);
    const excludedPropertyIds = exclProperties.map((p) => p.id);
    submit('nearby_properties_excluded_properties', excludedPropertyIds);
  };

  const onReferralSourceChange = (newReferralSource) => {
    setReferralSource(newReferralSource);
    submit('cross_sell_referral_source', newReferralSource);
  };

  /**
   * enable_cross_sell is a leasing_team level setting, so we are validating here to make sure the
   * leasing_team of the property and the group_id (aka leasing_team.id) of the leasing_team
   * settings are the same, and then checking to see if that leasing_team has cross sell enabled.
   */
  const isPropertyReferralsEnabled =
    settings &&
    settings.length > 0 &&
    settings.some(
      (setting) =>
        setting.group_id === property.leasing_team_id &&
        setting.enable_cross_sell
    );

  if (!isPropertyReferralsEnabled) {
    return (
      <div className="cross-sell-disabled" data-testid="cross-sell-disabled">
        Cross-Sell is not enabled for the Leasing Team associated with this
        Property. Please contact Knock Support if you’d like to enable this!{' '}
        <a
          data-testid="cross-sell-disabled-link"
          href="https://support.knockrentals.com/en/articles/5313938-cross-selling-aka-prospect-transferring"
        >
          Learn more.
        </a>
      </div>
    );
  }

  return (
    <div>
      <Grid
        container
        direction="column"
        spacing={3}
        justifyContent="space-evenly"
        alignItems="flex-start"
        className={classes.root}
      >
        <Typography variant="h6" gutterBottom>
          Referrals
        </Typography>
        <Typography variant="body1" gutterBottom>
          Radius:
        </Typography>
        <Grid item>
          <RadiusSelect
            radius={radius}
            onRadiusChange={onRadiusChanged}
            onRadiusReset={handleRadiusReset}
          />
          <Typography variant="caption" display="block" gutterBottom>
            Properites within range are eligible for referals (Default radius is{' '}
            {NearbyCommunities.DEFAULT_RADIUS} mi).
          </Typography>
        </Grid>
        <Typography variant="body1" gutterBottom>
          Nearby Communities:
        </Typography>
        <PropertyTransferList
          includedProperties={includedProperties}
          excludedProperties={excludedProperties}
          onIncludedPropertiesChange={handleIncludedPropertiesChange}
          onExcludedPropertiesChange={handleExcludedPropertiesChange}
        />
        <Grid item>
          <Typography variant="caption" display="block" gutterBottom>
            Properties in the "include" list are eligible for referrals from
            your community
          </Typography>
        </Grid>
        <Typography variant="body1" gutterBottom>
          Referral Source:
        </Typography>
        <Grid item>
          <Typography variant="caption" display="block">
            By default, a referred prospect keeps the same Source. If you would
            like to set a new designated value, you may choose from your list of
            enabled Sources on the <a href="/sources">/sources</a> page. Please
            note that if neither Source is set up at the referred Property then
            the default value that is set on the <a href="/sources">/sources</a>{' '}
            page will be used.
            <br />
            <br />
            Example: If the prospect Source was "Property Website" at Property
            A, then the prospect created at Property B will also have the Source
            "Property Website." If you designate a new value, the prospect
            referred to Property B will have this new value.
          </Typography>
        </Grid>
        <Grid item>
          <ReferralSourceSelect
            referralSource={referralSource}
            availableReferralSources={availableReferralSources}
            onReferralSourceChange={onReferralSourceChange}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default PropertyInfoNearbyCommunities;
