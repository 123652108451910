import React from 'react';
import PropTypes from 'prop-types';
import { ListItemSecondaryAction } from '@material-ui/core';
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
} from '@knockrentals/knock-shared-web';
import { PreferencesSelect } from '../PreferencesSelect';
import { IsModifiedCheckbox } from '../IsModifiedCheckbox';
import { getOptions } from '../utils';
import { PREFERENCES_TITLES, TIMEZONE } from '../constants';

const useStyles = makeStyles({
  timezone: {
    '& .MuiInputBase-root': {
      minWidth: '260px',
    },
  },
});

const Timezone = ({
  isBatchEdit,
  modifiedStatusMap = {},
  setTimezone,
  timezone,
  updateModifiedStatus,
}) => {
  const classes = useStyles();

  const updatePreference = ({ timezone }) => {
    setTimezone(timezone);
  };

  return (
    <List>
      <ListItem>
        <ListItemText>
          <IsModifiedCheckbox
            isModified={modifiedStatusMap[TIMEZONE]}
            updateModifiedStatus={updateModifiedStatus}
            name={TIMEZONE}
            value={timezone}
            resetPreference={updatePreference}
          />
          {PREFERENCES_TITLES[TIMEZONE]}
        </ListItemText>

        <ListItemSecondaryAction className={classes.timezone}>
          <PreferencesSelect
            ariaLabel={PREFERENCES_TITLES[TIMEZONE]}
            isIndeterminate={isBatchEdit && !timezone}
            name={TIMEZONE}
            options={getOptions(TIMEZONE)}
            value={timezone}
            updatePreference={updatePreference}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );
};

Timezone.propTypes = {
  isBatchEdit: PropTypes.bool,
  modifiedStatusMap: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.bool)])
  ),
  setTimezone: PropTypes.func,
  timezone: PropTypes.string,
  updateModifiedStatus: PropTypes.func,
};

export default Timezone;
